import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
	ApplicationInsights,
	ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { appConfig } from "./config";

const { aiConnectionString, aiRole } = appConfig();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		connectionString: aiConnectionString,
		extensions: [reactPlugin],
		extensionConfig: {},
		enableAutoRouteTracking: true,
		disableAjaxTracking: false,
		autoTrackPageVisitTime: true,
		enableCorsCorrelation: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
	},
});
appInsights.loadAppInsights();

if (aiRole !== undefined)
	appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
		env.tags = env.tags || [];
		env.tags["ai.cloud.role"] = aiRole;
	});

export { appInsights, reactPlugin };
