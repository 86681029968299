type TransitionTimeout =
	| number
	| {
			appear?: number | undefined;
			enter?: number | undefined;
			exit?: number | undefined;
	  }
	| {
			appear?: number | undefined;
			enter?: number | undefined;
			exit?: number | undefined;
	  };

export const fabZoomTimeout: TransitionTimeout = { enter: 500 };
