import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
} from "@mui/material";
import {
	InputField,
	NumberInputField,
} from "prodriver-react-hook-form-mui-components";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { theme } from "../AppTheme";
import useMemberApi from "../api/hooks/useMemberApi";
import { MemberPagedRequest } from "../api/models/MemberPagedRequest";
import { MemberProfile } from "../api/models/MemberProfile";
import { PagedList, PagedListMetaData } from "../api/models/PagedList";
import {
	FilterChoice,
	FilterChoiceSelect,
} from "../components/FilterChoiceSelect";
import { GridViewProps } from "../components/Paged/GridView";
import { ListViewProps } from "../components/Paged/ListView";
import { PagedListView } from "../components/Paged/PagedListView";
import {
	SortDirection,
	SortDirectionSelect,
} from "../components/SortDirectionSelect";

enum SearchType {
	memberId,
	firstLastName,
}

interface SearchTypeSelectionProps {
	onChange: (value: SearchType) => void;
	style?: React.CSSProperties;
	initialSelection?: SearchType;
}

const SearchTypeSelection = (props: SearchTypeSelectionProps) => {
	const { onChange, style, initialSelection = SearchType.memberId } = props;

	const [value, setValue] = useState<SearchType>(initialSelection);

	useEffect(() => {
		onChange(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		newValue: string
	) => {
		setValue(Number.parseInt(newValue) as SearchType);
	};

	return (
		<div style={{ position: "relative" }}>
			{/* { showHelp && <AddAUserHelp /> } */}
			<FormControl component='fieldset' style={{ ...style }}>
				<FormLabel component='legend'>Search By</FormLabel>
				<RadioGroup
					row
					aria-label='search type'
					name='search-type'
					value={value}
					onChange={handleChange}
				>
					<FormControlLabel
						value={SearchType.memberId}
						control={<Radio color='primary' />}
						label='Member ID'
					/>
					<FormControlLabel
						value={SearchType.firstLastName}
						control={<Radio color='primary' />}
						label='Other Criteria'
					/>
				</RadioGroup>
			</FormControl>
		</div>
	);
};

interface MemberDetails {
	firstName?: string;
	firstNameFilterChoice: FilterChoice;
	lastName?: string;
	lastNameFilterChoice: FilterChoice;
	email?: string;
	emailFilterChoice: FilterChoice;
	sortDirection: SortDirection;
}

interface FormData {
	memberId?: number;
	memberDetails?: MemberDetails;
}

const emptyMetaData: PagedListMetaData = {
	pageCount: 0,
	totalItemCount: 0,
	pageNumber: 0,
	pageSize: 0,
	firstItemOnPage: 0,
	lastItemOnPage: 0,
};

export const Members = () => {
	const [searchType, setSearchType] = useState<SearchType>(SearchType.memberId);
	const showFirstLastName = searchType === SearchType.firstLastName;
	const [sortLastName, setSortLastName] = useState<boolean>(true);
	const [members, setMembers] = useState<PagedList<MemberProfile>>({
		metaData: emptyMetaData,
		items: [],
	});
	const { getMembers } = useMemberApi();
	const intialValues: FormData = {
		memberDetails: {
			firstNameFilterChoice: FilterChoice.Contains,
			lastNameFilterChoice: FilterChoice.Contains,
			emailFilterChoice: FilterChoice["Starts With"],
			sortDirection: SortDirection.Ascending,
		},
	};
	const currentForm = useForm<FormData>({ defaultValues: intialValues });
	const {
		formState: { isValid },
		handleSubmit,
		watch,
	} = currentForm;

	const watchFirstName = watch("memberDetails.firstName");
	const watchLastName = watch("memberDetails.lastName");

	const onSearchTypeChanged = (value: SearchType) => {
		setSearchType(value);
	};

	const requireNumberOfChars = (value: string, numberRequired: number = 4) =>
		value && value.trim().length < numberRequired
			? `Must be at least ${numberRequired} characters`
			: true;

	const convertFilterChoice = (filterChoice: FilterChoice): boolean | null => {
		return filterChoice === FilterChoice.Contains
			? null
			: filterChoice === FilterChoice["Starts With"];
	};

	const createMemberRequest = (formData: FormData): MemberPagedRequest => {
		const memberRequest: MemberPagedRequest = {
			firstNameIsStartWith: null,
			sortAscendingByFirstName: null,
			lastNameIsStartWith: null,
			sortAscendingByLastName: null,
			emailIsStartWith: null,
		};
		if (searchType === SearchType.memberId)
			memberRequest.memberId = formData.memberId;
		else if (formData.memberDetails === undefined) {
			//Should not happen
			console.log("Error search not configured correctly");
		} else {
			if (
				formData.memberDetails.firstName &&
				formData.memberDetails.firstName.length > 0
			)
				memberRequest.firstName = formData.memberDetails.firstName;
			memberRequest.firstNameIsStartWith = convertFilterChoice(
				formData.memberDetails.firstNameFilterChoice
			);
			if (
				formData.memberDetails.lastName &&
				formData.memberDetails.lastName.length > 0
			)
				memberRequest.lastName = formData.memberDetails.lastName;
			memberRequest.lastNameIsStartWith = convertFilterChoice(
				formData.memberDetails.lastNameFilterChoice
			);
			if (sortLastName)
				memberRequest.sortAscendingByLastName =
					formData.memberDetails.sortDirection === SortDirection.Ascending;
			else
				memberRequest.sortAscendingByFirstName =
					formData.memberDetails.sortDirection === SortDirection.Ascending;
		}
		console.log("member request", { memberRequest });
		return memberRequest;
	};

	const fetchMembers = async (memberRequest: MemberPagedRequest) => {
		const membersRequest = await getMembers(memberRequest);
		const members: PagedList<MemberProfile> = membersRequest.data;
		setMembers(members);
	};

	const onSubmit = async (formData: FormData) => {
		const memberRequest: MemberPagedRequest = createMemberRequest(formData);
		await fetchMembers(memberRequest);
	};

	const pageChanged = async (page: number) => {
		const memberRequest: MemberPagedRequest = createMemberRequest(
			currentForm.getValues()
		);
		console.log("page", { page });
		memberRequest.pageNumber = page;
		await fetchMembers(memberRequest);
	};

	const pageSizeChanged = async (pageSize: number) => {
		const memberRequest: MemberPagedRequest = createMemberRequest(
			currentForm.getValues()
		);
		memberRequest.pageSize = pageSize;
		await fetchMembers(memberRequest);
	};

	const onSelectMember = () => {

	}

	const createMemberGridView = (member: MemberProfile): GridViewProps => {
		return {
			key: `matrix-member=${member.id}`,
			primaryTitle: (
				<span style={{ color: theme.palette.grey[600] }}>{`${
					member.firstName || ""
				} ${member.lastName || ""}`}</span>
			),
			secondaryTitle: member.email,
			style: {
				backgroundColor: theme.palette.grey[100],
				display: "flex",
				flexDirection: "column",
			},
			onClick: onSelectMember
		};
	};

	const createMemberListView = (member: MemberProfile): ListViewProps => {
		return {
			key: `matrix-member=${member.id}`,
			primaryTitle: (
				<span style={{ color: theme.palette.grey[600] }}>{`${
					member.firstName || ""
				} ${member.lastName || ""}`}</span>
			),
			secondaryTitle: member.email,
			style: {
				backgroundColor: theme.palette.grey[100],
			},
			onClick: onSelectMember
		};
	};

	return (
		<FormProvider {...currentForm}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2} xs={12} style={{ margin: 0 }}>
					<Grid item xs={12}>
						<SearchTypeSelection
							onChange={onSearchTypeChanged}
							initialSelection={searchType}
						/>
					</Grid>
					<Grid item xs={12}>
						<NumberInputField
							name='memberId'
							fullWidth
							label='Member ID'
							style={{ flex: 1 }}
							decimalScale={0}
							allowNegative={false}
							validateOnChange
							disabled={searchType !== SearchType.memberId}
							rules={{
								required:
									searchType === SearchType.memberId
										? "Member ID is required"
										: false,
								validate: {
									lengthLimit: (v: number) =>
										v && v.toString().length > 9
											? "Member ID cannot be greater than 9 digits"
											: true,
								},
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<FilterChoiceSelect
							name='memberDetails.firstNameFilterChoice'
							disabled={!showFirstLastName}
							label='First Name'
						/>
					</Grid>
					<Grid item xs={8}>
						<InputField
							name='memberDetails.firstName'
							disabled={!showFirstLastName}
							rules={{
								validate: {
									required: (v: string) =>
										showFirstLastName && !watchLastName && v.trim().length === 0
											? "First Name or Last Name is required"
											: true,
									requireNumChars: (v: string) => requireNumberOfChars(v),
								},
							}}
							fullWidth
							maxLength={100}
						/>
					</Grid>
					<Grid item xs={4}>
						<FilterChoiceSelect
							name='memberDetails.lastNameFilterChoice'
							disabled={!showFirstLastName}
							label='Last Name'
						/>
					</Grid>
					<Grid item xs={8}>
						<InputField
							name='memberDetails.lastName'
							disabled={!showFirstLastName}
							rules={{
								validate: {
									required: (v: string) =>
										showFirstLastName &&
										!watchFirstName &&
										v.trim().length === 0
											? "First Name or Last Name is required"
											: true,
									requireNumChars: (v: string) => requireNumberOfChars(v),
								},
							}}
							fullWidth
							maxLength={100}
						/>
					</Grid>
					<Grid xs={12}>
						<FormLabel id='radio-group'>Sort By</FormLabel>
						<RadioGroup name='radio-group'>
							<FormControlLabel
								value='Choice 1'
								control={
									<Radio
										checked={!sortLastName}
										onChange={() => setSortLastName(false)}
										value={false}
									/>
								}
								label='First Name'
							/>
							<FormControlLabel
								value='Choice 2'
								control={
									<Radio
										checked={sortLastName}
										onChange={() => setSortLastName(true)}
										value={true}
									/>
								}
								label='Last Name'
							/>
						</RadioGroup>
					</Grid>
					<Grid item xs={12}>
						<FormLabel id='radio-group'>Sort Direction</FormLabel>
						<SortDirectionSelect name='memberDetails.sortDirection' />
					</Grid>
					<Grid item xs={12}>
						<Button type='submit' disabled={!isValid}>
							Fetch
						</Button>
					</Grid>
					<Grid item xs={12}>
						<PagedListView
							entityName='Member'
							items={members}
							createGridView={createMemberGridView}
							createListView={createMemberListView}
							onPageChange={pageChanged}
							onPageSizeChange={pageSizeChanged}
						/>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};
