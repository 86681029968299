import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { BusyButton } from "./BusyButton";

interface ConfirmDialogProps {
    isOpen: boolean;
    title: React.ReactNode;
    content: React.ReactNode;
    children?: React.ReactNode;
    okButtonText?: string;
    cancelButtonText?: string;
    failMessage?: string;
    action: () => any;
    onActionSuccess: () => void;
    onActionFail?: () => void;
    onCancel: () => void;
}

export const ConfirmDialog = ({
    onCancel,
    content,
    action,
    isOpen,
    onActionSuccess,
    onActionFail,
    title,
    children,
    okButtonText = 'OK',
    cancelButtonText = 'CANCEL',
    failMessage = 'An error occurred',
}: ConfirmDialogProps) => {
    const [isActionPending, setIsActionPending] = useState(false);

    const handleConfirmClick = async () => {
        setIsActionPending(true);
        try {
            await Promise.resolve(action());
            onActionSuccess();
        } catch (err) {
            if (onActionFail) {
                onActionFail();
                return;
            }
            console.log('Error in confirmation', { err, failMessage });
            // dispatch(
            //     addAlert({
            //         message: failMessage,
            //         severity: 'error',
            //     }),
            // );
        } finally {
            setIsActionPending(false);
        }
    };

    const handleCancelClick = () => {
        if (isActionPending) {
            return;
        }
        onCancel();
    };

    return (
        <Dialog disableEscapeKeyDown maxWidth="xs" open={isOpen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {content}
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick} color="primary">
                    {cancelButtonText}
                </Button>
                <BusyButton
                    onClick={handleConfirmClick}
                    color="primary"
                    isActionPending={isActionPending}
                >
                    {okButtonText}
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
};