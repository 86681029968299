import { SelectField } from "prodriver-react-hook-form-mui-components";

export enum FilterChoice {
	Contains,
	"Starts With",
	"Ends With",
}

interface FilterChoiceSelectProps {
	name: string;
	disabled?: boolean;
	label?: string;
}

export const FilterChoiceSelect = (props: FilterChoiceSelectProps) => {
	const { disabled, name, label } = props;

	return (
		<SelectField<string>
			name={name}
			disabled={disabled}
			items={Object.keys(FilterChoice).filter((v) => Number.isNaN(Number(v)))}
			itemValue={(v) => FilterChoice[v as keyof typeof FilterChoice]}
			itemDisplay={(v: string) => v}
			itemKey={(v) => `${name}-${v}`}
			label={label || ""}
		/>
	);
};
