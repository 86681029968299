import {
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
} from "@mui/material";
import { CSSProperties } from "react";

export interface ListViewProps {
	key?: React.Key | null | undefined;
	primaryTitle?: React.ReactNode;
	secondaryTitle?: React.ReactNode;
	borderColor?: string;
	style?: CSSProperties;
	onClick?: () => void;
	avatar?: React.ReactNode;
	actions?: React.ReactNode;
}

export const ListView = (props: ListViewProps) => {
	const {
		key,
		primaryTitle,
		secondaryTitle,
		borderColor,
		style,
		onClick,
		avatar,
		actions,
	} = props;
	//const allowClick = onClick !== undefined;
	const allowActions = actions !== undefined;

	return (
		<ListItem
			key={`list-item-${key}`}
			//disableRipple={!allowClick}
			onClick={onClick}
			style={{ borderLeft: `5px solid ${borderColor}`, ...style }}
			// classes={{
			//   root: allowClick ? undefined : itemClass.noClick,
			//   secondaryAction: isXsDown ? itemClass.secondaryActionXs : undefined,
			// }}
		>
			{avatar !== undefined && <ListItemAvatar>{avatar}</ListItemAvatar>}
			<ListItemText primary={primaryTitle} secondary={secondaryTitle} />
			{allowActions && (
				<ListItemSecondaryAction>{actions}</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};
