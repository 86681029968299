import { AccountInfo } from "@azure/msal-browser";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { Profile } from "./models/Profile";

const accountInfoToAzureAccount = (accountInfo: AccountInfo): AzureAccount => ({
	fullAccount: accountInfo,
	email: accountInfo.username,
});

export interface AzureAccount {
	email: string;
	fullAccount: AccountInfo;
}

export interface AuthenticationState {
	idToken?: string;
	azureAccount?: AzureAccount;
	profile?: Profile;
}

const initialAuthenticationState: AuthenticationState = {};

const authenticationSlice = createSlice({
	name: "authentication",
	initialState: initialAuthenticationState,
	reducers: {
		setAzureAccount: (
			state: AuthenticationState,
			action: PayloadAction<AccountInfo>
		) => {
			state.azureAccount = accountInfoToAzureAccount(action.payload);
		},
		removeAzureAccount: (state: AuthenticationState) => {
			state.azureAccount = undefined;
			state.profile = undefined;
		},
		setProfile: (
			state: AuthenticationState,
			action: PayloadAction<Profile>
		) => {
			state.profile = action.payload;
		},
	},
});

export const { setAzureAccount, removeAzureAccount, setProfile } =
	authenticationSlice.actions;

export const selectAzureAccount = (
	state: RootState
): AzureAccount | undefined => state.authentication.azureAccount;

export const selectProfile = (
	state: RootState
): Profile | undefined => state.authentication.profile;

export default authenticationSlice.reducer;
