import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppAlertSeverity } from "./AppAlertSeverity";

interface AppAlert {
	message: React.ReactNode;
	autoHideDuration?: number;
	severity?: AppAlertSeverity;
}

export interface ApplicationState {
	blockingRequests: number;
	alert?: AppAlert;
}

export const initialApplicationState: ApplicationState = {
	blockingRequests: 0,
};

export const applicationSlice = createSlice({
	name: "application",
	initialState: initialApplicationState,
	reducers: {
		incrementBlockingRequests: (state) => {
			state.blockingRequests++;
		},
		decrementBlockingRequests: (state) => {
			state.blockingRequests--;
		},
		addAlert: (state, action: PayloadAction<AppAlert>) => {
			state.alert = action.payload;
		},
	},
});

export const {
	incrementBlockingRequests,
	decrementBlockingRequests,
	addAlert,
} = applicationSlice.actions;

export default applicationSlice.reducer;
