import { Card } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactNode, useState } from "react";

interface CardViewProps {
	children: ReactNode;
	allowClick?: boolean;
	normalElevations?: number;
	hoverElevations?: number;
	style?: CSSProperties;
}

const cardWrapperStyles = makeStyles(() =>
	createStyles({
		root: {
			"& .MuiCardHeader-content": {
				alignSelf: "start",
			},
		},
	})
);

export const CardView = (props: CardViewProps) => {
	const {
		children,
		allowClick = true,
		hoverElevations,
		normalElevations,
		style,
	} = props;
	const [isHovered, setIsHovered] = useState(false);
	const classes = cardWrapperStyles();

	return (
		<Card
			elevation={
				allowClick ? (isHovered ? hoverElevations : normalElevations) : 0
			}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{ ...style }}
			className={classes.root}
		>
			{children}
		</Card>
	);
};
