import { memoize } from "lodash";
import { MapSchema, parseEnv } from "./envUtilities";

const configSchema = {
	portalApiBaseUrl: { kind: "string" },
	msalScopes: { kind: "string" },
	msalClientId: { kind: "string" },
	msalClientAuthority: { kind: "string" },
	msalClientRedirectUrl: { kind: "string" },
	aiConnectionString: { kind: "string" },
	aiRole: { kind: "string", optional: true },
} as const;

export type AppConfig = MapSchema<typeof configSchema>;

export const appConfig: () => AppConfig = memoize(() =>
	parseEnv<AppConfig>(configSchema, "PRODRIVER_ADMIN_ENV")
);

export const getMsalConfiguration = () => {
	const config = appConfig();
	return {
		auth: {
			clientId: config.msalClientId,
			authority: config.msalClientAuthority,
			knownAuthorities: [config.msalClientAuthority],
			redirectUri: config.msalClientRedirectUrl,
		},
		cache: {
			cacheLocation: "localStorage",
		},
	};
};
