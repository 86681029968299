import { UserPermission } from "../../authentication/models/Profile";
import { useApiClient } from "./useApiClient";

export const useUserApi = () => {
	const { apiClient } = useApiClient();
	const controllerName: string = "User";

	const getPermissions = () => {
		return apiClient.get<UserPermission[]>(`${controllerName}/permissions`);
	};
	return { getPermissions };
};

export default useUserApi;
