import { ListIterateeCustom, findIndex } from "lodash";

export const addOrReplace = <T>(
	items: T[],
	predicate: ListIterateeCustom<T, boolean>,
	newItem: T
) => {
	const index = findIndex(items, predicate);
	if (index === -1) {
		return [...items, newItem];
	}
	items.splice(index, 1, newItem);
	return items;
};
