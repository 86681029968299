import { FormFields } from "../FormField";

export const addonFormFields: FormFields = {
	name: {
		name: "name",
		label: "Name*",
		requiredErrorMsg: "Name is required",
	},
	price: {
		name: "price",
		label: "Price*",
		requiredErrorMsg: "Price is required",
	},
	isActive: {
		name: "isActive",
		label: "Is Active",
	},
	metaName: {
		name: "metaName",
		label: "Meta Name",
	},
	checkoutName: {
		name: "checkoutName",
		label: "Checkout Name",
	},
	isCompanyPaid: {
		name: "isCompanyPaid",
		label: "Is Company Paid",
	},
};
