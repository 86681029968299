import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@mui/styles";
import { theme } from "../AppTheme";

const useStyles = makeStyles(() => ({
    commonPadding: {
        paddingLeft: 18,
        paddingRight: 18,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 2,
            paddingRight: 2,
        },
    },
    topBottomPadding: {
        paddingTop: 25,
        paddingBottom: 25,
    },
}));

interface ContentPaddingProps extends TransitionProps {
    children?: React.ReactNode;
    hasTopBottomPadding?: boolean;
    containerStyle?: React.CSSProperties;
}

export const ContentPadding = (props: ContentPaddingProps) => {
    const {
        children, containerStyle, hasTopBottomPadding: includeTopBottomPadding = false, ...rest
    } = props;
    const classes = useStyles();

    return (
        <div
            className={`${classes.commonPadding} ${includeTopBottomPadding ? classes.topBottomPadding : ''}`}
            {...rest}
            style={containerStyle}
        >
            {children}
        </div>
    );
};
