import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authenticationReducer from "../authentication/authenticationReducer";
import applicationSliceReducer from './applicationSlice';

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        application: applicationSliceReducer,
      },/*
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    }),*/
});

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();  

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void, ParType = unknown> = ThunkAction<ReturnType, RootState, ParType, Action<string>>;