import { SelectField } from "prodriver-react-hook-form-mui-components";

export enum SortDirection {
	Ascending,
	Descending,
}

interface SortDirectionSelectProps {
	name: string;
	disabled?: boolean;
	label?: string;
}

export const SortDirectionSelect = (props: SortDirectionSelectProps) => {
	const { disabled, name, label } = props;

	return (
		<SelectField<string>
			name={name}
			disabled={disabled}
			items={Object.keys(SortDirection).filter((v) => Number.isNaN(Number(v)))}
			itemValue={(v) => SortDirection[v as keyof typeof SortDirection]}
			itemDisplay={(v: string) => v}
			itemKey={(v) => `${name}-${v}`}
			label={label || ""}
		/>
	);
};
