import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Dialog, IconButton, Paper, Slide, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { theme } from "../AppTheme";
import { ConfirmDialog } from './ConfirmDialog';
import { ContentPadding } from "./ContentPadding";

const Transition = React.forwardRef(
    (
        props: TransitionProps & { children?: React.ReactElement },
        ref: React.Ref<unknown>,
    ) => <Slide direction="up" ref={ref} {...props}><div>{props.children}</div></Slide>,
);

interface ConfirmCloseProps {
    showDialog: boolean;
    title?: string;
    content?: string;
    isOpen?: boolean;
    onCancel?: () => void;
}

interface StyleProps {
    maxWidth: any;
    maxContentWidth: any;
}

const useStyles = makeStyles((props: StyleProps) => ({
    title: {
        marginLeft: 4,
        display: 'inline-block',
    },
    // appBar: {
    //   position: "relative",
    // },
    detailContentContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'start',
        backgroundColor: theme.palette.detailWrapperBgColor ? theme.palette.detailWrapperBgColor.main : 'whitesmoke',
        [theme.breakpoints.only('xs')]: {
            padding: 8,
        },
    },
    baseContainer: {
        padding: '2em',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            padding: '1em',
        },
    },
    dialogRoot: {
        [theme.breakpoints.up('lg')]: {
            width: '85%',
            margin: 'auto',
            maxWidth: props.maxWidth ?? 1860,
        },
        '& div[role="presentation"]': {
            height: '100%',
        },
    },
    dialogRootContent: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        maxWidth: props.maxContentWidth ?? 1800,
        [theme.breakpoints.only('xs')]: {
            '& .MuiGrid-container': {
                maxWidth: 'unset !important',
            },
            '& form': {
                padding: '8px !important',
            },
        },
    },
}));

interface DetailsDialogProps {
    isOpen: boolean;
    title: string;
    isModal?: boolean;
    disallowEscapeKeyNavigation?: boolean;
    maxWidth?: any;
    maxContentWidth?: any;
    hideHoritizontalScrollbar?: boolean;
    titleBackgroundColor?: string;
    isTitleSticky?: boolean;
    isCloseDisabled?: boolean;
    confirmCloseDialog?: ConfirmCloseProps;
    children: React.ReactNode;
    headerContentPaddingStyle?: React.CSSProperties;
    onClose?: () => void;
}

export const DetailsDialog = (props: DetailsDialogProps) => {
    const { isOpen, title, isModal = false, disallowEscapeKeyNavigation, maxWidth, maxContentWidth, hideHoritizontalScrollbar = false, titleBackgroundColor, isTitleSticky = false, isCloseDisabled, confirmCloseDialog, children, headerContentPaddingStyle, onClose, ...additionalProps } = props
    const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
    const classes = useStyles({ maxWidth, maxContentWidth });

    useEffect(() => {
        if (isConfirmOpen !== confirmCloseDialog?.isOpen) {
            setIsConfirmOpen(confirmCloseDialog?.isOpen ?? false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmCloseDialog?.isOpen])

    const onDialogClose = (event: {}, reason?: "backdropClick" | "escapeKeyDown") => {
        if (isModal || (disallowEscapeKeyNavigation && reason === "escapeKeyDown"))
            return;
        if (reason !== "backdropClick") {
            if (confirmCloseDialog?.showDialog) {
                setIsConfirmOpen(true);
            } else if (onClose !== undefined) {
                onClose();
            }
        }
    }

    return <>
        <Dialog
            open={isOpen}
            onClose={onDialogClose}
            disableEscapeKeyDown={isModal || disallowEscapeKeyNavigation}
            classes={{ root: classes.dialogRoot }}
            {...additionalProps}
            fullScreen
            PaperProps={{
                style: {
                    overflowX: hideHoritizontalScrollbar ? 'hidden' : 'initial',
                },
            }}
            TransitionComponent={Transition}
        >
            <AppBar
                style={{
                    backgroundColor: titleBackgroundColor ?? theme.palette.primary.main,
                }}
                position={isTitleSticky ? 'sticky' : 'relative'}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onDialogClose}
                        aria-label="close"
                        disabled={isModal || isCloseDisabled}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        noWrap
                        style={{ width: '100%' }}
                    >
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper square className={classes.detailContentContainer}>
                <ContentPadding
                    style={{ display: 'flex', flex: 1 }}
                    hasTopBottomPadding
                    containerStyle={headerContentPaddingStyle}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            // overflowX: "hidden",
                        }}
                    >
                        <Box className={classes.dialogRootContent}>
                            {children}
                        </Box>
                    </Box>
                </ContentPadding>
            </Paper>
        </Dialog >

        <ConfirmDialog
            isOpen={isConfirmOpen}
            action={() => {
                setIsConfirmOpen(false);
                if (onClose !== undefined)
                    onClose();
            }}
            content={confirmCloseDialog?.content}
            onActionSuccess={() => {
                setIsConfirmOpen(false);
            }}
            onCancel={() => {
                setIsConfirmOpen(false);
                if (confirmCloseDialog?.onCancel) {
                    confirmCloseDialog.onCancel();
                }
            }}
            title={confirmCloseDialog?.title}
        />
    </>
}