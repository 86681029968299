import * as Yup from "yup";

declare module "yup" {
	interface StringSchema {
		currency(): StringSchema;
	}
}

const currencyRegex =
	/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

Yup.addMethod(Yup.string, "currency", function (this: Yup.StringSchema) {
	return this.test((v) => (v === undefined ? false : currencyRegex.test(v)));
});

export const loadCurrencyYupMethod = () => {};
