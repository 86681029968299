import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
	interface Palette {
		neutralButton: { main: string };
		detailWrapperBgColor: { main: string };
	}

	interface PaletteOptions {
		neutralButton?: { main: string };
		detailWrapperBgColor: { main: string };
	}
}

const defaultTheme = createTheme();
export const theme = createTheme({
	palette: {
		primary: {
			main: "#005C9D",
		},
		secondary: {
			main: "#C90000",
		},
		background: {
			default: "#ebe9eb",
		},
		success: {
			main: "#34b233",
		},
		detailWrapperBgColor: {
			main: "whitesmoke",
		},
		text: {
			primary: "#001524",
		},
		neutralButton: {
			main: "#e0e0e0",
		},
	},
	typography: {
		summaryLabel: {
			...defaultTheme.typography.subtitle2,
			color: defaultTheme.palette.text.secondary,
		},
		summaryValue: {
			...defaultTheme.typography.h6,
			fontSize: "1.04rem",
			color: defaultTheme.palette.text.primary,
		},
		h6: {
			...defaultTheme.typography.h6,
			fontSize: "19px !important",
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					fontSize: "0.875rem",
					lineHeight: 1.43,
					letterSpacing: "0.01071em",
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: "standard",
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: "standard",
			},
		},
	},
});

export const accordionDetailsGradient =
	"linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(235,233,235,1) 100%)";

// MUI v5 will allow custom variants to be available as a legal key vor the Typogrpahy prop "variant"
// https://github.com/mui-org/material-ui/issues/15573
// ... for now, they can be defined at the theme level but they can only be referenced via javascript (available via theme)
declare module "@mui/material/styles/createTypography" {
	interface Typography {
		summaryLabel: React.CSSProperties;
		summaryValue: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyOptions {
		summaryLabel: React.CSSProperties;
		summaryValue: React.CSSProperties;
	}
}
