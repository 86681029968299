import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useProductApi from "../api/hooks/useProductApi";
import { Addon } from "../authentication/models/Addon";
import { AddonDialog } from "../components/Addon/AddonDialog";
import { AddonView } from "../components/Addon/AddonView";
import { addOrReplace } from "../utilities/ArrayHelper";

export const Addons = () => {
	const [addons, setAddons] = useState<Addon[]>([]);
	const [currentAddon, setCurrentAddon] = useState<Addon>();
	const [isAddonDialogOpen, setIsAddonDialogOpen] = useState<boolean>(false);
	const { getAddons } = useProductApi();
	const routeParams = useParams();
	const addonId: string | undefined = routeParams["addonId"];

	useEffect(() => {
		const loadAddons = async () => {
			try {
				const { data } = await getAddons();
				setAddons(data);
				if (addonId !== undefined) {
					const addon = data.find(a => a.id === addonId);
					setCurrentAddon(addon);
					setIsAddonDialogOpen(addon !== undefined);
				}
			} catch (error: any) {
				console.log("addon error", { error });
			}
		};

		loadAddons();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSelectAddon = (addon: Addon) => {
		setIsAddonDialogOpen(true);
		setCurrentAddon(addon);
	}

	const onAddonDialogClose = () => {
		setIsAddonDialogOpen(false);
		setCurrentAddon(undefined);
	}

	const onCreateAddon = () => {
		setCurrentAddon(undefined);
		setIsAddonDialogOpen(true);
	}

	const onAddonSaved = (addon: Addon) => {
		const updatedAddons = addOrReplace(addons, { id: addon.id }, addon);
		setAddons(updatedAddons);
		onAddonDialogClose();
	}

	return <>
		<AddonView addons={addons} onClick={onSelectAddon} onCreateAddon={onCreateAddon} />
		<AddonDialog key='addons' isOpen={isAddonDialogOpen} addon={currentAddon} onClose={onAddonDialogClose} onAddonSaved={onAddonSaved} />
	</>;
};
