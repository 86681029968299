import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../AppTheme";
import { ItemsView, ItemsViewProps } from "./ItemsView";
import { PageHeader, PageHeaderProps } from "./PageHeader";

const useStyles = makeStyles({
  container: {
    alignContent: 'start',
  },
  collectionControls: {
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
      marginLeft: 0,
    },
  },
  pagingControls: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      flexBasis: '100%',
      justifyContent: 'space-around',
    },
  },
  toggleViewButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginTop: 20,
    },
  },
  pageSizes: {
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
});

interface ItemsPageProps<T> extends ItemsViewProps<T>, Omit<PageHeaderProps, 'style'> {
  headerStyle?: React.CSSProperties;
}

export const ItemsPage = <T,>(props: ItemsPageProps<T>) => {
  const { items, showNoItems, createGridView, createListView, isCardView = true, leftBlock, rightBlock, headerStyle } = props
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <PageHeader leftBlock={leftBlock} rightBlock={rightBlock} style={headerStyle} />
      </Grid>
      <Grid item xs={12}>
        <ItemsView items={items} createGridView={createGridView} createListView={createListView} showNoItems={showNoItems} isCardView={isCardView} />
      </Grid>
    </Grid>
  )
}