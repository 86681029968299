import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { reactPlugin } from "./ApplicationInsightsService";
import { useAuthentication } from "./authentication/useAuthentication";
import useProfile from "./authentication/useProfile";
import { Addons } from "./pages/Addons";
import { Home } from "./pages/Home";
import { Members } from "./pages/Members";

function App() {
	const { isAuthenticated, logout } = useAuthentication();
	const { profile } = useProfile();

	return (
		<div className='App'>
			<AppInsightsContext.Provider value={reactPlugin}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					{profile && (
						<>
							<span>Welcome </span>
							{profile.firstName}
						</>
					)}
					{isAuthenticated && <Button onClick={logout}>Logout</Button>}
					<Router>
						<Routes>
							<Route path='Members' element={<Members />} />
							<Route path='Addons/:addonId?' element={<Addons />} />
							<Route path='*' element={<Home />} />
						</Routes>
					</Router>
				</LocalizationProvider>
			</AppInsightsContext.Provider>
		</div>
	);
}

export default App;
