import { Add } from '@mui/icons-material';
import { Box, Fab, Tooltip, Zoom } from "@mui/material";
import { theme } from "../../AppTheme";
import { Addon } from "../../authentication/models/Addon";
import { fabZoomTimeout } from "../../transitions/Timeout";
import { fabTransitionDelay } from "../../transitions/TransitionDelays";
import { currencyFormat } from "../../utilities/CurrencyFormat";
import { ItemsPage } from "../ItemsPage";
import { GridViewProps } from "../Paged/GridView";
import { ListViewProps } from "../Paged/ListView";

interface AddonViewProps {
  addons: Addon[];
  isCardView?: boolean
  onClick: (addon: Addon) => void;
  onCreateAddon: () => void;
}

const showNoItems = () => {
  return <>No addons</>
}

export const AddonView = (props: AddonViewProps) => {
  const { addons, isCardView = true, onClick, onCreateAddon } = props;

  const createGridView = (addon: Addon): GridViewProps => {
    return {
      key: `matrix-member=${addon.id}`,
      primaryTitle: (
        <span style={{ color: theme.palette.grey[600] }}>{addon.name}</span>
      ),
      secondaryTitle: `Price: ${currencyFormat(addon.price)}`,
      style: {
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        flexDirection: "column",
      },
      onClick: () => onClick(addon)
    };
  };

  const createListView = (addon: Addon): ListViewProps => {
    return {
      key: `matrix-member=${addon.id}`,
      primaryTitle: (
        <span style={{ color: theme.palette.grey[600] }}>{addon.name}</span>
      ),
      secondaryTitle: `Price: ${currencyFormat(addon.price)}`,
      style: {
        backgroundColor: theme.palette.grey[100],
      },
      onClick: () => onClick(addon)
    };
  };

  const createRightBlockHeader = () => {
    return <>
      <Zoom
        in
        timeout={fabZoomTimeout}
        unmountOnExit
        style={{ transitionDelay: fabTransitionDelay }}
      >
        <Box>
          <Tooltip title="Add a Addon" aria-label="add a addon">
            <Fab
              aria-label="Add addon"
              variant="extended"
              size="small"
              color="secondary"
              onClick={onCreateAddon}
            >
              <Add />
              {' '}
              Add Addon
            </Fab>
          </Tooltip>
        </Box>
      </Zoom>
    </>
  }

  return <ItemsPage items={addons} createGridView={createGridView} createListView={createListView} showNoItems={showNoItems} isCardView={isCardView} rightBlock={createRightBlockHeader()} />
}