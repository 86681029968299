import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid } from "@mui/material";
import { AxiosResponse } from "axios";
import { CheckboxField, InputField, NumberInputField } from "prodriver-react-hook-form-mui-components";
import { BaseSyntheticEvent, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { theme } from "../../AppTheme";
import useProductApi from "../../api/hooks/useProductApi";
import { Addon, AddonNew } from "../../authentication/models/Addon";
import { addonFormFields } from "../../models/Addon/AddonFormFields";
import { AddonDefaultType, addonValidationScheme } from "../../models/Addon/AddonValidationScheme";
import { DetailsDialog } from "../DetailsDialog";

const { name, price, isActive, metaName, checkoutName, isCompanyPaid } =
  addonFormFields;

interface AddonDialogProps {
  addon?: Addon;
  isOpen: boolean;
  onClose?: () => void;
  onAddonSaved?: (addon: Addon) => void;
}

export const AddonDialog = (props: AddonDialogProps) => {
  const { addon, isOpen, onClose, onAddonSaved } = props;
  const { addAddon, updateAddon } = useProductApi();
  const addonName: string = addon === undefined ? '' : addon.name

  const currentForm = useForm({
    mode: "onChange",
    resolver: yupResolver(addonValidationScheme),
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = currentForm;

  useEffect(() => {
    const defaultValue: AddonDefaultType = addon === undefined ? {} : addon
    reset(defaultValue);
  }, [addon, reset]);

  const handleSave = async <T,>(addon: T, call: (addon: T) => Promise<AxiosResponse<Addon, any>>) => {
    const response = await call(addon);
    if (response.status === 200) {
      if (onAddonSaved !== undefined)
        onAddonSaved(response.data);
      return true;
    }
    alert(`Something went wrong ${response.status} - ${response.statusText}`)
    return false;
  }

  const submitData = async (values: any, e: BaseSyntheticEvent | undefined) => {
    try {
      if (addon === undefined) {
        const addonNew: AddonNew = values;
        await handleSave(addonNew, addAddon);
      }
      else {
        await handleSave(values as Addon, updateAddon);
      }
    }
    catch (ex: any) {
      console.log('Error saving', { ex });
    }
  };

  return <>
    <DetailsDialog title={`Addon - ${addonName}`} isOpen={isOpen} titleBackgroundColor={theme.palette.primary.dark} onClose={onClose}>
      <FormProvider {...currentForm}>
        <form
          id='AddForm'
          onSubmit={handleSubmit((d, e: BaseSyntheticEvent | undefined) => {
            submitData(d, e);
          })}
        >
          <Grid container spacing={2} xs={12} style={{ margin: 0 }}>
            <Grid item xs={12}>
              <InputField
                name={name.name}
                label={name.label}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberInputField
                name={price.name}
                label={price.label}
                decimalScale={2}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField
                name={isActive.name}
                label={isActive.label}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                name={metaName.name}
                label={metaName.label}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberInputField
                name={checkoutName.name}
                label={checkoutName.label}
                decimalScale={2}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField
                name={isCompanyPaid.name}
                label={isCompanyPaid.label}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!isValid}
                type='submit'
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

    </DetailsDialog>
  </>
}