import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useUserApi from "../api/hooks/useUserApi";
import { useAppDispatch } from "../app/store";
import { selectAzureAccount, selectProfile, setProfile } from "./authenticationReducer";
import { Profile } from "./models/Profile";

type IdTokenClaimsType = {
	[key: string]: string | number | string[] | object | undefined | unknown;
};

const useProfile = () => {
	const dispatch = useAppDispatch();
	const firstRenderRef = useRef(true);
	const [profileLoading, setProfileLoading] = useState(false);
	const profile = useSelector(selectProfile);
	const azureAccount = useSelector(selectAzureAccount);
	const { getPermissions } = useUserApi();

	useEffect(() => {
		if (firstRenderRef.current) {
			firstRenderRef.current = false;
			return;
		}
		const loadProfile = async () => {
			if (profileLoading) return;
			setProfileLoading(true);
			if (azureAccount === undefined) throw new Error("No Azure account");
			try {
				const { data } = await getPermissions();
				const idTokenClaims: IdTokenClaimsType = azureAccount.fullAccount
					.idTokenClaims as IdTokenClaimsType;
				const profile: Profile = {
					id: azureAccount.fullAccount.localAccountId,
					firstName: idTokenClaims["given_name"] as string,
					lastName: idTokenClaims["family_name"] as string,
					email: azureAccount.email,
					permissions: data,
				};
				dispatch(setProfile(profile));
			} catch (ex: any) {
				console.log("Error retrieving profile", { ex });
			}
			setProfileLoading(false);
		};

		if (
			azureAccount !== undefined &&
			(profile === undefined ||
				azureAccount.fullAccount.localAccountId !== profile.id)
		)
			loadProfile();
		//else if (profile) setProfile(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [azureAccount]);

	return {
		profileLoading,
		profile,
	};
};

export default useProfile;
