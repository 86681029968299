import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PagedList } from "../../api/models/PagedList";
import { ItemsView, ItemsViewBaseProps } from "../ItemsView";
import { PagedListPagination } from "./PagedListPagination";

interface PageListGridProps<T> extends ItemsViewBaseProps<T> {
	entityName: string;
	items: PagedList<T>;
	onPageChange: (page: number) => void;
	onPageSizeChange: (pageSize: number) => void;
}

const useStyles = makeStyles({
	container: {
		alignContent: "start",
	},
});

export const PagedListView = <T,>(props: PageListGridProps<T>) => {
	const {
		entityName,
		items: { items, metaData },
		createGridView,
		createListView,
		onPageChange,
		onPageSizeChange,
		isCardView = true,
	} = props;
	const classes = useStyles();
	const hasItems: boolean = metaData.totalItemCount !== 0;

	const showNoItems = () => {
		const text = `No ${entityName}s found`;
		return <>{text}</>;
	};

	return (
		<>
			<Grid container className={classes.container}>
				<Grid item xs={12}>
					<ItemsView items={items} createGridView={createGridView} createListView={createListView} showNoItems={showNoItems} isCardView={isCardView} />
				</Grid>
				{hasItems &&
					<Grid item xs={12}>
						<PagedListPagination
							metaData={metaData}
							onPageChange={onPageChange}
							onPageSizeChange={onPageSizeChange}
						/>
					</Grid>}
			</Grid>
		</>
	);
};
