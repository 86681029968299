import { Grid } from "@mui/material";
import { GridView, GridViewProps } from "./Paged/GridView";
import { ListView, ListViewProps } from "./Paged/ListView";

export interface ItemsViewBaseProps<T> {
  createGridView: (item: T) => GridViewProps;
  createListView: (item: T) => ListViewProps;
  isCardView?: boolean;
}

export interface ItemsViewProps<T> extends ItemsViewBaseProps<T> {
  items: T[];
  showNoItems: () => JSX.Element;
}

export const ItemsView = <T,>(props: ItemsViewProps<T>) => {
  const { items, showNoItems, createGridView, createListView, isCardView = true } = props
  const hasItems: boolean = items !== undefined && items.length !== 0;

  const showItems = () => {
    if (!hasItems)
      return showNoItems();
    return items.map((item) => {
      if (isCardView) {
        const props = createGridView(item);
        return <GridView {...props} />;
      }
      const props = createListView(item);
      return <ListView {...props} />;
    });
  };

  return <Grid container spacing={2} xs={12}>
    {showItems()}
  </Grid>
}