import axios from "axios";
import { useAuthentication } from "../../authentication/useAuthentication";
import { appConfig } from "../../config";

export const useApiClient = () => {
	const { getAccessToken } = useAuthentication();
	const { portalApiBaseUrl } = appConfig();

	const getClient = (url: string) => {
		const apiClient = axios.create({
			baseURL: url,
			responseType: "json",
		});
	
		return apiClient;
	};
	
	const apiClient = getClient(portalApiBaseUrl);
	
	apiClient.interceptors.request.use(async (config) => {
		config.headers["Authorization"] = `Bearer ${await getAccessToken()}`;
		config.headers["Accept"] = "application/json";
		return config;
	});	

	return { apiClient }
}
