import { Addon, AddonNew } from "../../authentication/models/Addon";
import { useApiClient } from "./useApiClient";

export const useProductApi = () => {
	const { apiClient } = useApiClient();
	const controllerName: string = "Product";
	const addonControllerName: string = `${controllerName}/Addon`;

	const getAddons = () => {
		return apiClient.get<Addon[]>(`${addonControllerName}`);
	};

	const addAddon = (addon: AddonNew) => {
		return apiClient.post<Addon>(`${addonControllerName}`, addon);
	};

	const updateAddon = (addon: Addon) => {
		return apiClient.put<Addon>(`${addonControllerName}`, addon);
	};

	return { getAddons, addAddon, updateAddon };
};

export default useProductApi;
