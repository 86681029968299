import { toQueryString } from "../../utilities/QueryParams";
import { MemberPagedRequest } from "../models/MemberPagedRequest";
import { MemberProfile } from "../models/MemberProfile";
import { PagedList } from "../models/PagedList";
import { useApiClient } from "./useApiClient";

export const useMemberApi = () => {
	const { apiClient } = useApiClient();
	const controllerName: string = "Member";

	const getMembers = (memberRequest: MemberPagedRequest) => {
		return apiClient.get<PagedList<MemberProfile>>(
			`${controllerName}?${toQueryString(memberRequest)}`
		);
	};

	return { getMembers };
};

export default useMemberApi;
