type MapSchemaTypes = {
    string: string;
    integer: number;
    // any others, like these?
    boolean: boolean;
    float: number;
    number: number;
    regexp: RegExp;
    // more? 
}

type ConfigParameter = Readonly<{
    kind: keyof MapSchemaTypes;
    optional?: boolean;
}>;
    
const toEnvName = (str: string) => {
    return 'REACT_APP' + str.replace(/(?:[a-z]*[^A-Z]|[A-Z][a-z]*)/g, function(word)
    {
        return '_' + word.toUpperCase();
    }).replace(/\s+/g, '');
}

type SchemaType = Record<string, ConfigParameter>;

export type MapSchema<T extends SchemaType> = {
    -readonly [K in keyof T as T[K]['optional'] extends true ? K : never]?: MapSchemaTypes[T[K]['kind']]
} & {
    -readonly [K in keyof T as T[K]['optional'] extends false ? K : never]: MapSchemaTypes[T[K]['kind']]
} & {
    -readonly [K in keyof T as T[K]['optional'] extends infer O ? O & K : never]: MapSchemaTypes[T[K]['kind']]
}

export const string = (optional?: boolean) : ConfigParameter => { return { kind: 'string', optional: optional ?? false } };

export const parseEnv = <T>(
	schema: SchemaType,
	windowName: string = "ENV"
): T => {
	const ret: any = {};
	// @ts-ignore
	const env = { ...process.env, ...window[windowName] };
	for (const name in schema) {
		ret[name] = env[toEnvName(name)];
	}
	return ret;
};