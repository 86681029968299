import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../AppTheme";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '2em',
    marginBottom: '1.5em',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  paging: {
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  leftBlock: {
    paddingRight: 12,
    [theme.breakpoints.down('sm')]: {
      order: 2,
      flexBasis: '100%',
      paddingLeft: 0,
    },
  },
  rightBlock: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      justifyContent: 'center',
      marginBottom: 10,
    },
  },
}));

export interface PageHeaderProps {
  leftBlock?: React.ReactNode;
  rightBlock?: React.ReactNode;
  style?: React.CSSProperties;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { leftBlock, rightBlock, style } = props;
  const classes = useStyles();

  return <Box display="flex" className={classes.container} style={{ ...style }}>
    <Box flex="1" className={classes.leftBlock}>
      {leftBlock}
    </Box>

    {rightBlock && (
      <Box className={classes.rightBlock} display="flex">
        {rightBlock}
      </Box>
    )}
  </Box>

}