import {
	EventType,
	InteractionRequiredAuthError,
	SilentRequest,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../app/store";
import { appConfig } from "../config";
import {
	removeAzureAccount,
	selectAzureAccount,
	setAzureAccount,
} from "./authenticationReducer";

export interface AuthenticationService {
	isAuthenticated: boolean;
	getAccessToken: () => Promise<string>;
	logout: () => void;
}

export const useAuthentication = (): AuthenticationService => {
	const { instance } = useMsal();
	const isAuthenticated: boolean = useIsAuthenticated();
	const { msalScopes } = appConfig();
	const dispatch = useDispatch();
	const azureAccount = useSelector(selectAzureAccount);

	useEffect(() => {
		if (isAuthenticated) {
			const account = getAccount();
			console.log("authentication", { azureAccount, account });
			if (
				azureAccount === undefined ||
				azureAccount.fullAccount.localAccountId !== account.username
			)
				dispatch(setAzureAccount(account));
		}
		//instance.enableAccountStorageEvents();
		const callbackId = instance.addEventCallback((message: any) => {
			if (message.eventType === EventType.LOGIN_SUCCESS) {
				instance.setActiveAccount(message.payload.account);
				store.dispatch(setAzureAccount(message.payload.account));
			} else if (message.eventType === EventType.LOGOUT_SUCCESS) {
				store.dispatch(removeAzureAccount());
			} else if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
				store.dispatch(setAzureAccount(message.payload.account));
			} else if (message.eventType === EventType.ACCOUNT_ADDED) {
				// const account = message.payload;
				// Update UI - account change on another tab
			} else if (message.eventType === EventType.ACCOUNT_REMOVED) {
				// const account = message.payload;
				// Update UI - account change on another tab
			}
		});
		return () => {
			//sinstance.disableAccountStorageEvents();
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAccount = () => {
		const account = instance.getActiveAccount();
		if (account === null) {
			const [account] = instance.getAllAccounts();
			instance.setActiveAccount(account);
			return account;
		}
		return account;
	};

	const getAccessToken = async (): Promise<string> => {
		await instance.initialize();
		// if (inProgress !== InteractionStatus.None)
		//     return "";
		//const [account] = instance.getAllAccounts();
		const account = getAccount();
		if (!account) {
			console.log("no account");
			return "";
		}
		const tokenRequest: SilentRequest = {
			account: account,
			scopes: msalScopes?.split(","),
		};
		try {
			const { accessToken } = await instance.acquireTokenSilent(tokenRequest);
			return accessToken;
		} catch (e: any) {
			if (e instanceof InteractionRequiredAuthError) {
				await instance.acquireTokenRedirect(tokenRequest);
			}
			console.log("access token error", { e });
			// throw e;
			return "";
		}
	};

	const logout = () => {
		instance.logoutRedirect().catch((e: any) => {
			console.error(e);
		});
	};

	return {
		isAuthenticated: isAuthenticated,
		getAccessToken,
		logout,
	};
};
