import { Backdrop, Button, ButtonProps, CircularProgress, Portal } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface BusySubmitButtonProps extends ButtonProps {
    isActionPending: boolean;
    containerStyles?: React.CSSProperties;
    applyInvisibleBackdrop?: boolean;
}

export const BusyButton = (props: BusySubmitButtonProps) => {
    const useStyles = makeStyles(() => ({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        invisOverlay: {
            zIndex: 9000,
        },
    }));
    const classes = useStyles();
    const {
        isActionPending,
        children,
        disabled,
        containerStyles,
        applyInvisibleBackdrop = true,
        ...rest
    } = props;

    return (
        <>
            <div style={{ position: 'relative', ...containerStyles }}>
                <Button {...rest} disabled={isActionPending || disabled}>
                    {children}
                </Button>
                {isActionPending && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </div>
            {isActionPending && (
                <>
                    {applyInvisibleBackdrop && (
                        <Portal>
                            <Backdrop
                                open={isActionPending}
                                classes={{
                                    root: classes.invisOverlay,
                                }}
                                invisible
                            />
                        </Portal>
                    )}
                </>
            )}
        </>
    );
};
