import { CardActions, CardHeader, Grid } from "@mui/material";
import { CSSProperties } from "react";
import { theme } from "../../AppTheme";
import { CardView } from "./CardView";

export interface GridViewProps {
	key?: React.Key | null | undefined;
	primaryTitle?: React.ReactNode;
	secondaryTitle?: React.ReactNode;
	borderLeftColor?: string;
	minHeight?: number;
	style?: CSSProperties;
	avatar?: React.ReactNode;
	onClick?: () => void;
	actions?: React.ReactNode;
	actionStyle?: React.CSSProperties;
}

export const GridView = (props: GridViewProps): JSX.Element => {
	const {
		key,
		primaryTitle: title,
		secondaryTitle: subheader,
		borderLeftColor = theme.palette.common.black,
		minHeight = 125,
		style,
		avatar,
		onClick,
		actions,
		actionStyle,
	} = props;
	const allowClick = onClick !== undefined;
	const allowActions = actions !== undefined;

	return (
		<Grid key={key} onClick={onClick} item xs={12} sm={6} md={6} lg={3}>
			<CardView
				allowClick={allowClick}
				style={{
					borderLeft: `5px solid ${borderLeftColor}`,
					minHeight,
					...style,
				}}
			>
				<CardHeader
					titleTypographyProps={{ noWrap: true }}
					subheaderTypographyProps={{ noWrap: true }}
					title={title}
					subheader={subheader}
					onClick={onClick}
					// className={
					//   userCanClickItem
					//     ? classes.cardHeader
					//     : classes.cardHeaderNoClick
					// }
					// classes={{
					//   title: classes.cardTitle,
					//   subheader: classes.cardSubTitle,
					//   content: classes.forceEllipsis,
					// }}
					avatar={avatar}
				/>
				{allowActions && actions && (
					<CardActions
						style={{ justifyContent: "space-between", ...actionStyle }}
					>
						{actions}
					</CardActions>
				)}
			</CardView>
		</Grid>
	);
};
