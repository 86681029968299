import * as Yup from "yup";
import { Addon } from "../../authentication/models/Addon";
import { loadCurrencyYupMethod } from "../../utilities/CurrencyYupMethod";
import { addonFormFields } from "./AddonFormFields";

const { name, price, isActive, metaName, checkoutName, isCompanyPaid } =
	addonFormFields;

loadCurrencyYupMethod();

export type AddonDefaultType = Partial<Omit<Addon, "id">>;

export const addonValidationScheme = Yup.object<AddonDefaultType>({
	[name.name]: Yup.string().max(50).required(name.requiredErrorMsg),
	[price.name]: Yup.string().currency().required(price.requiredErrorMsg),
	[isActive.name]: Yup.boolean().required(),
	[metaName.name]: Yup.string().max(50),
	[checkoutName.name]: Yup.string().max(50),
	[isCompanyPaid.name]: Yup.boolean().required(),
});
