import {
	Configuration,
	InteractionType,
	PublicClientApplication,
} from "@azure/msal-browser";
import {
	MsalAuthenticationResult,
	MsalAuthenticationTemplate,
	MsalProvider,
} from "@azure/msal-react";
import { Alert, AlertTitle } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";
import { getMsalConfiguration } from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const msalConfig: Configuration = getMsalConfiguration();

const msalInstance = new PublicClientApplication(msalConfig);

const MsalLoginRedirect = () => {
	return <div>Logging in ....</div>;
};

interface MsalErrorProps {
	msalAuthResult: MsalAuthenticationResult;
}
const MsalError = (props: MsalErrorProps) => {
	const { msalAuthResult } = props;
	const { error } = msalAuthResult;
	// This code represents a user-cancellation action, so
	// if the user cancelled, just send them to the login page
	if (error?.errorMessage && error?.errorMessage.includes("AADB2C90091")) {
		window.location.pathname = "/";
		return null;
	}
	return (
		<Alert severity='error'>
			<AlertTitle>Error - {error ? error.name : "Unknown Error"}</AlertTitle>
			{error
				? error.errorMessage
				: "An unknown error occurred, please try again."}
		</Alert>
	);
};

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<MsalProvider instance={msalInstance}>
				<MsalAuthenticationTemplate
					interactionType={InteractionType.Redirect}
					loadingComponent={MsalLoginRedirect}
					errorComponent={(errorResult: MsalAuthenticationResult) => (
						<MsalError msalAuthResult={errorResult} />
					)}
				>
					<App />
				</MsalAuthenticationTemplate>
			</MsalProvider>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
