import { TablePagination } from "@mui/material";
import { PagedListMetaData } from "../../api/models/PagedList";

interface PagedListPaginationProps {
	metaData: PagedListMetaData;
	onPageChange: (page: number) => void;
	onPageSizeChange: (pageSize: number) => void;
}
export const PagedListPagination = (props: PagedListPaginationProps) => {
	const { metaData, onPageChange, onPageSizeChange } = props;

	return (
		<TablePagination
			count={metaData.totalItemCount}
			rowsPerPage={metaData.pageSize}
			page={metaData.pageNumber - 1}
			onPageChange={(e, p) => onPageChange(p + 1)}
			onRowsPerPageChange={(e) => onPageSizeChange(parseInt(e.target.value))}
		/>
	);
};
